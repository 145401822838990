<template>
  <div class="mainPage">
    <el-breadcrumb separator="/">
      <el-breadcrumb-item>{{$t('resetPWD.account')}}</el-breadcrumb-item>
      <el-breadcrumb-item>{{$t('resetPWD.udpatePWD')}}</el-breadcrumb-item>
    </el-breadcrumb>
    <el-card class="box-card" shadow="never"><br>
    <el-form ref="pwdform" :model="pwdform" label-width="100px" :rules="rules">
      <el-form-item :label="$t('resetPWD.oldPassword')" prop="oldPassword">
        <el-input
          type="password"
          v-model="pwdform.oldPassword"
          maxlength="20"
          style="width: 300px;"
        ></el-input>
      </el-form-item>
      <el-form-item :label="$t('resetPWD.newPassword')" prop="newPassword">
        <el-input
          type="password"
          v-model="pwdform.newPassword"
          maxlength="20"
          style="width: 300px;"
        ></el-input>
      </el-form-item>
      <el-form-item :label="$t('resetPWD.newPassword2')" prop="newPassword2">
        <el-input
          type="password"
          v-model="pwdform.newPassword2"
          maxlength="20"
          style="width: 300px;"
        ></el-input>
      </el-form-item>

      <el-form-item>
        <el-button type="primary" @click="submitForm('pwdform')"
          >{{$t('resetPWD.update')}}</el-button
        >
      </el-form-item>
    </el-form>
  </el-card>
  </div>
</template>

<script>
import { updatePassword } from "@/api/account";
import { mapState } from "vuex";
export default {
  name: "ResetPassword",
  data() {
    var validatePass2 = (rule, value, callback) => {
      if (value !== this.pwdform.newPassword) {
        callback(new Error("两次输入密码不一致!"));
      } else {
        callback();
      }
    };
    return {
      pwdform: {
        oldPassword: "",
        newPassword: "",
        newPassword2: ""
      },

      rules: {
        oldPassword: [
          { required: true, message: "请输入密码", trigger: "blur" },
          { min: 8, max: 20, message: "长度在 8 到 20 个字符", trigger: "blur" }
        ],
        newPassword: [
          { required: true, message: "请输入新密码", trigger: "blur" },
          { min: 8, max: 20, message: "长度在 8 到 20 个字符", trigger: "blur" }
        ],
        newPassword2: [
          { required: true, message: "请输入确认密码", trigger: "blur" },
          { validator: validatePass2, trigger: "blur" }
        ]
      }
    };
  },
  computed: {
    ...mapState(["accountInfo"])
  },
  methods: {
    submitForm(formName) {
      this.$refs[formName].validate(valid => {
        if (valid) {
          updatePassword({
            userID: this.accountInfo.userID,
            oldPassword: this.pwdform.oldPassword,
            newPassword: this.pwdform.newPassword
          }).then(response => {
            this.$message({
              message: response.message,
              type: "success"
            });
          });
        } else {
          return false;
        }
      });
    }
  }
};
</script>
